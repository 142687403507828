<template>
  <v-form ref="form" v-model="valid" lazy-validation v-if="groups">
    <!-- <v-card outlined>
      <v-card flat>
        <v-card-text class="px-2 pt-7"> -->
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="selectedGroup"
              :items="groups"
              item-text="groupName"
              item-value="guid"
              return-object
              :label="$t('prescriptions.prescriptionsGroupName')"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-card flat>
          <v-card-actions
            :style="
              $vuetify.theme.isDark
                ? 'background-color:#182e32'
                : 'background-color:whitesmoke'
            "
            class="elevation-1"
          >
            <v-row class="ma-2" no-gutters>
              <v-col style="font-size: 20px" class="button mt-2">
                {{ $t("prescriptions.prescriptionsItems") }}
              </v-col>
              <v-col cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      dark
                      class="float-right"
                      @click="addItem"
                    >
                      <v-icon> mdi-plus</v-icon>
                    </v-btn>
                  </template>

                  <span class="">
                    {{ $t("prescriptions.addPrescriptionItem") }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-actions>
          <!-- <v-card-actions>
                  <div style="width: 100%">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="blue darken-1"
                          dark
                          class="float-right"
                          @click="addItem"
                        >
                          <v-icon> mdi-plus</v-icon>
                        </v-btn>
                      </template>

                      <span class="">
                        {{ $t("prescriptions.addPrescriptionItem") }}
                      </span>
                    </v-tooltip>
                  </div>
                </v-card-actions> -->

          <v-card-text>
            <v-list>
              <v-list-item v-for="(item, i) in prescriptionModels" :key="i">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-combobox
                          :label="$t('prescriptions.itemName')"
                          v-model="item.itemName"
                          :items="items"
                          item-text="itemName"
                          item-value="itemName"
                          @change="
                            (val) => {
                              item.brandName = val.brandName
                                ? val.brandName
                                : '';
                              item.itemName = val.itemName ? val.itemName : val;
                            }
                          "
                          return-object
                          persistent-hint
                          single-line
                          :hint="
                            item.brandName
                              ? 'Brand Name: ' + item.brandName
                              : ''
                          "
                        >
                        </v-combobox>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field
                          :label="$t('prescriptions.dose')"
                          v-model="item.dose"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field
                          :label="$t('prescriptions.usageItem')"
                          v-model="item.usageItem"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field :label="$t('notes')" v-model="item.note">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider />
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="removeItem(i)"
                        icon
                        color="red lighten-2"
                      >
                        <v-icon> mdi-close </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("remove") }}
                    </span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>
          <!-- </v-virtual-scroll> -->
        </v-card>
      </v-col>
    </v-row>
    <!-- </v-card-text>
      </v-card> -->

    <v-card-actions class="px-3">
      <v-btn
        color="green darken-1"
        class="white--text"
        @click="save"
        :disabled="!valid"
        :min-width="100"
      >
        <v-icon>mdi-content-save-outline</v-icon>
        {{ $t("save") }}
      </v-btn>
      <v-btn
        color="blue darken-1"
        class="white--text"
        @click="cancelEdit"
        :class="isEdit ? '' : 'd-none'"
      >
        <v-icon>mdi-newspaper-variant-outline</v-icon>
        {{ $t("new") }}
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn to="/prescriptionGroup" color="blue darken-1" text>
          {{ $t("backToList") }}
        </v-btn> -->
    </v-card-actions>
    <!-- </v-card> -->
  </v-form>
</template>

<script>
export default {
  props: ["guid", "editPrescriptionModel"],

  data() {
    return {
      selectedItem: 1,
      isEdit: false,
      collectionGuid: "",
      valid: true,
      groups: [],
      selectedGroup: {},
      defultGroup: {
        branchGuid: "",
        dateReg: "",
        doctorGuid: "",
        groupName: "",
        guid: "",
        note: "",
        prescriptionTemplates: [],
        userGuid: "",
      },
      prescriptionModels: [],
      items: [],
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    if (this.notEmptyGuid()) {
      this.refreshGroup();
    }
    this.$axios
      .get("PrescriptionItems")
      .then((response) => {
        this.items = response.data.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  watch: {
    selectedGroup(val) {
      var x = JSON.parse(JSON.stringify(val.prescriptionTemplates));
      for (var i = 0; i < x.length; i++) {
        var y = x[i];
        this.prescriptionModels.push({
          dose: y.dose,
          usageItem: y.usageItem,
          note: y.note,
          itemName: y.prescriptionItem.itemName,
          brandName: y.prescriptionItem.brandName,
        });
      }
    },

    editPrescriptionModel(item) {
      //console.log(item);
      this.prescriptionModels = item.masterDetail;
      this.collectionGuid = item.collectionGuid;
      this.isEdit = true;
    },
  },

  computed: {},
  methods: {
    notEmptyGuid() {
      return this.guid != null && this.guid != undefined && this.guid != "";
    },

    addItem() {
      this.prescriptionModels.push({});
    },

    removeItem(index) {
      this.prescriptionModels.splice(index, 1);
    },

    cancelEdit() {
      this.prescriptionModels = [];
      this.isEdit = false;
    },

    refreshGroup() {
      this.$axios
        .get("PrescriptionGroup/GetWithTemplates")
        .then((response) => {
          this.groups = response.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    save() {
      if (this.prescriptionModels.length <= 0) {
        this.valid = false;

        this.$toast.error(this.$t("prescriptions.addSomeItemsBeforeSave"));

        return;
      }

      var val = this.$refs.form.validate();

      if (val) {
        if (this.isEdit) {
          var updateData = {
            prescriptionModels: this.prescriptionModels,
            collectionGuid: this.collectionGuid,
            appointmentGuid: this.guid,
          };
          this.$axios
            .post("Prescription/Update", updateData)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.prescriptionModels = [];
                this.selectedGroup = Object.assign({}, this.defultGroup);
                this.isEdit = false;
              }
              this.$emit("savePresecription", response);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          var data = {
            prescriptionModels: this.prescriptionModels,
            appointmentGuid: this.guid,
          };
          if (this.notEmptyGuid()) {
            this.$axios
              .post("Prescription/Add", data)
              .then((response) => {
                if (response.data.status == "Successful") {
                  this.prescriptionModels = [];
                  this.selectedGroup = Object.assign({}, this.defultGroup);
                }
                this.$emit("savePresecription", response);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
      }
    },
  },
};
</script>

<style></style>
